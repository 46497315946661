<template>
  <div>
    <div id="park" class="main-box park-program">
      <el-row>
        <el-col :span="24">
          <div class="main-description">园区解决方案</div>
          <div class="park-program-minSpan">SOLUTION</div>
        </el-col>
      </el-row>

      <el-row type="flex" justify="center">
        <el-col :span="7">
          <div class="program-list">
            <ul>
              <li
                :class="item.class"
                v-for="(item, idx) in programList"
                :key="idx"
                @mouseover="mouseover(idx)"
              >
                <span>{{ idx + 1 }}</span>
                <span>{{ item.content }}</span>
                <img src="~@/assets/展开.png" alt="" />
              </li>
            </ul>
          </div>
        </el-col>
        <el-col :span="7">
          <!-- <div class="program-content">
          </div> -->
          <img
            class="program-content"
            :src="programList[this.currentIndex].img"
            alt=""
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import bus from "@/utils/bus";

let _this = null;
export default {
  name: "park",
  data() {
    return {
      currentIndex: 0,
      programList: [
        {
          content: "园区/企业赋能中心",
          class: "program-list-content li-hover",
          img: require("@/assets/园区-企业赋能中心.png"),
        },
        {
          content: "园区/企业新媒体中心",
          class: "program-list-content",
          img: require("@/assets/园区-企业新媒体中心.png"),
        },
        {
          content: "园区/企业云平台",
          class: "program-list-content",
          img: require("@/assets/工业互联网人才培训站点.png"),
        },
        {
          content: "园区/企业智管中心",
          class: "program-list-content",
          img: require("@/assets/园区-企业智管中心.png"),
        },
      ],
    };
  },
  created() {
    _this = this;
  },
  mounted(){
        bus.$on("park-message", () => {
      this.$el
        .querySelector("#park")
        .scrollIntoView({ behavior: "smooth" });
    });

  },
  methods: {
    mouseover(idx) {
      this.currentIndex = idx;
      this.programList.forEach(function (val, index) {
        if (index != idx) {
          _this.programList[index].class = "program-list-content";
        } else {
          _this.programList[idx].class = "program-list-content li-hover";
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/*内容盒子*/
.main-box {
  padding: 70px 0;
}
// 产品与解决方案
.main-description {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}
.main-description:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 12px;
  width: 12px;
  margin-right: 40px;
  background-color: #2965ed;
}
.main-description:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 12px;
  width: 12px;
  margin-left: 40px;
  background-color: #2965ed;
}
/*园区解决方案*/
.park-program {
  .park-program-minSpan {
    font-family: SourceHanSansCN-Normal;
    font-size: 20px;
    color: #e7e7e7;
    letter-spacing: 3.1px;
    text-align: center;
    margin: 30px 0;
  }
  // 内容列表
  .program-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    align-items: flex-start;
  }
  .program-content {
    width: 100%;
    height: 100%;
    //  background: url("~@/assets/工业互联网人才培训站点.png") no-repeat;
  }
  .program-list-content{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .program-list {
    span {
      display: inline-block;
      margin-right: 30px;
    }
    img {
      float: right;
      opacity: 0;
    }
    ul {
      width: 100%;
    }
    li {
      font-family: SourceHanSansCN-Normal;
      font-size: 24px;
      color: #808080;
      padding: 30px 40px 30px 40px;
      background: rgb(245, 247, 253);
      cursor: pointer;
    }
    .li-hover {
      color: #ffffff;
      background-image: linear-gradient(159deg, #8fc6fe 0%, #2965ed 100%);
      img {
        opacity: 1;
      }
    }
    li:hover {
      color: #ffffff;
      background-image: linear-gradient(159deg, #8fc6fe 0%, #2965ed 100%);
      img {
        opacity: 1;
      }
    }
  }
}
</style>
